.Service_profile_services {
    margin-bottom: 30px;
}
.services_wrapper {
    display: flex;
    /*justify-content: space-between;*/
    flex-wrap: wrap;
}

.service_link {
    text-decoration: none;
    background-color: #e9ecef;
    margin: 10px;
    padding: 5px 20px;
    border: 1px solid #c0c4c9;
    border-radius: 5px;
}

@media only screen and (max-width: 630px) {
    .Service_profile_services {
        font-size: 13px;
    }
}
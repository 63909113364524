.login_wrapper {
    width: 300px;
    height: 170px;
    margin: 30vh auto 0;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.login_item {
    width: 100%;
    padding: 10px 8px;
    /*border-bottom-left-radius: 3px;*/
    /*border-top-left-radius: 3px;*/
    border: none;
    border-bottom: 1px solid black;
    border-right-color: transparent;
    outline: none;
}

.login_item:focus {
    border: none;
    border-bottom: 1px solid #38a9f0;
    box-shadow: none;
}

.login_button {
    /*width: 318px;*/
    height: 37px;
    /*text-transform: uppercase;*/
    background-color: #e9ecef;
    border: 1px solid #e9ecef;
    /*border-radius: 5px;*/
    cursor: pointer;
    margin-top: 10px;
}

.login_item_wrapper {
    display: flex;
    align-items: end;
}

.icon_wrapper {
    border: 1px solid black;
    border-left: none;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_icon {
    margin-right: 10px;
}

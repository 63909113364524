.filter_wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: end;
}

.date_wrap {
    /*width: 500px;*/
    display: flex;
    align-items: center;
}

.filter_item {
    width: 300px;
    height: 38px;
    margin-top: 20px;
}

.buttons_wrapper {
    margin-top: 20px;
    width: 390px;
    display: flex;
    justify-content: space-between;
}

.filter_button {
    width: 180px;
}

.excell_btn {
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    background-color: #198754;
    color: #fff;
}

.excell_btn:hover{
    color: #fff;
    background-color: #10643d;
}

@media only screen and (max-width: 2300px) {
    .filter_item {
        width: 210px;
    }
    
    .filter_button {
        width: 100px;
    }
}

@media only screen and (max-width: 1350px) {
    .filter_item {
        width: 160px;
    }
    .buttons_wrapper {
        width: 210px;
    }
    .filter_button {
        width: 100px;
    }
}

@media only screen and (max-width: 1120px) {
    .filter_item {
        width: 220px;
    }
    .buttons_wrapper {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 730px) {
    .filter_item {
        width: 300px;
    }
}

@media only screen and (max-width: 630px) {
    .filter_item {
        font-size: 14px;
        width: 100%;
    }
    .buttons_wrapper {
        width: 100%;
    }
    .date_wrap {
        display: contents;
        width: 100%;
    }
}

@media only screen and (max-width: 530px) {
    .filter_item {
        font-size: 14px;
        width: 100%;
    }
}
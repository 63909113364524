.Device_logs {
    
}

@media only screen and (max-width: 630px) {
    .Device_logs {
        font-size: 14px;
    }
    .date_wrap input{
        font-size: 14px;
    }
}
.Data_table {
    text-align: left;
    font-size: 15px;
}
.tableItem {
    
}

.refund_button {
    width: 160px;
}

.top_wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
@media only screen and (max-width: 800px) {
    .Data_table {
        font-size: 8px;
    }
    .tableItem {
        padding: 2px;
    }
}
@media only screen and (max-width: 500px) {
    .refund_button {
        width: 60px;
        font-size: 10px;
    }
}
.Pagination {
    margin-top: 40px;
}
.pagination_wrap {
    display: flex;
    justify-content: space-between; 
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.pagination {
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  background-color: black ;
  border-color: black ;
  color: rgb(244, 244, 244);
}
.pagination > li > a{
  border: 1px solid black ;
  background-color: rgb(224, 227, 230);
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #0d6efd;
  border-color: black;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: black;
  margin-left: 20px; 
  border-radius: 3px;
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}

@media only screen and (max-width: 800px) {
  /*.pagination_wrap {*/
  /*  display: inline-block;*/
  /*}*/
}
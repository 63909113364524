.Custom_autocomplete {
  position: relative;
  display: flex;
  min-height: 38px;
  flex-direction: column;
}

.Custom_autocomplete:focus-within .autocomplete_input, .suggestions, .no-suggestions, .closeIcon {
    z-index: 10001;
}

.autocomplete_input {
  border: 1px solid #ced4da;
  /* width: 100%; */
  /* height: 25px; */
  border-radius: 0.25rem;
  padding: 8px 20px 8px 8px;
  outline: none;
}

.closeIcon {
  position: absolute;
  right: 7px;
  top: 1px;
  transform: rotate(45deg);
  font-size: 23px;
  border-radius: 50%;
  cursor: pointer;
  color: #dc3644;
  font-weight: 250;
  z-index: initial;
}

  .no-suggestions {
    color: #999;
    background-color: #fbfbfb;
    border-radius: 0.25rem;
    padding: 0.5rem;
  }
  
  .suggestions {
    position: absolute;
    top: 38px;
    border: 1px solid #999;
    background-color: #fbfbfb;
    border-top-width: 0;
    border-radius: 0.25rem;
    list-style: none;
    margin-top: 0;
    max-height: 180px;
    width: 100%;
    overflow-y: auto;
    padding-left: 0;
  }
  
  .suggestions li {
    padding: 2px 20px;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #1c1e1e;
    color: #f9fafb;
    cursor: pointer;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }

input[disabled]{
  color: #212529;
  background-color: #e9ecef;
}

@media only screen and (max-width: 630px) {
  .autocomplete_input {
    font-size: 14px;
  }
}

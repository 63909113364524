.refund_transaction_form {
    margin: 30px;
}
.refund_transaction_form_item_inner {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
}

.sum {
    margin-bottom: 10px;
}

.comment {
    margin-bottom: 10px;
}

.refund_transaction_form_label {
    width: 25%;
}

.refund_transaction_form_input {
    width: 60%;
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.refund_transaction_form_item_warning {
    color: red;
    font-size: 12px;
    text-align: center;
}

.transaction_refund_button {
    color: #fff;
    background-color: #0d6efd;
    border: none;
    border-radius: 5px;
    width: 110px;
    height: 45px;
}

.transaction_refund_button[disabled] {
    color: #fff;
    background-color: gray;
}

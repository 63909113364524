.Legenda {
    width: 700px;
    margin-bottom: 20px;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.color_box {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    display: inline-block;
}

@media only screen and (max-width: 1000px) {
    .Legenda {
        width: 100%;
        font-size: 14px;
    }
}

@media only screen and (max-width: 600px) {
    .Legenda {
        width: 100%;
        font-size: 10px;
    }
}
.Toolbar {
    margin-bottom: 20px;
}
.Hamburger {
    display: none;
}
.Toolbar_items {
    display: flex;
    justify-content: space-between;
}

.links_wrapper {
    display: flex;
    justify-content: space-between;
    width: 855px;
}
.mobile_links_wrapper {
    display: flex;
}

@media only screen and (max-width: 1200px) {
    .links_wrapper {
        width: 700px;
        font-size: 13px;
    }
}

@media only screen and (max-width: 930px) {
    .links_wrapper {
        width: 600px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 840px) {
    .Toolbar {
        display: none;
    }
    .Hamburger {
        display: block;
    }
    .links_wrapper {
        width: 600px;
        font-size: 12px;
    }
}
.App {
  text-align: center;
}

.custom_container {
  width: 95%;
  margin: 0 auto;
}

.breadcrumb_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 25px;
}

.breadcrumb_link {
  text-decoration: none;
}

.table_links {
  color: black;
  text-decoration: underline;
}

.date_wrap {
  display: flex;
  align-items: center;
  position: relative;
}

.date_wrap_label {
  position: absolute;
  top: -5px;
  left: 35%;
  color: #747678;
}

/*.filter_item {*/
/*  width: 260px;*/
/*  height: 38px;*/
/*  margin-top: 20px;*/
/*}*/

.row_form {
  margin-top: 25px;
}

.crud_button {
  width: 100%;
  max-width: 300px;
  margin: 20px 0;
}

.invoice_cancel_btn {
  width: 80px;
}

.invoice_cancel_wrapper {
  display: flex; 
  justify-content: space-between;
  margin-top: 20px;
}

.activeTerminalLabel {
  margin-right: 20px;
}

.paymentValue {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 830px) {
  .breadcrumb_wrapper {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 530px) {
  .breadcrumb_wrapper {
    display: inline-block;
  }
  /*.breadcrumb {*/
  /*  display: inline-block;*/
  /*}*/
}

.qr_btns_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.create_invoice_wrapper{
    padding: 20px;
}

.create_invoice_row {
    padding: 10px;
    display: flex;
    
}


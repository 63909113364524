.progress_container {
    height: 20px;
    width: 100%;
    background-color: #e9ecef;
    border-radius: 50px;
}

.progress_filler {
    height: 100%;
    border-radius: inherit;
    text-align: right;
}

.progress_label {
    padding: 5px;
    color: white;
    font-weight: bold;
}
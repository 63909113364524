.Service_profile_change_services {
    display: flex;
    margin-bottom: 30px;
}

.service_profile_change_title {
    display: flow;
}

.available_services_wrap {
    width: 30%;
}

.profile_services {
    margin-left: 20px;
}

.services_wrap {
    max-height: 500px;
    overflow: scroll;
}

.service_item {
    width: 90%;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e9ecef;
    padding: 5px;
    border: 1px solid #c0c4c9;
    border-radius: 5px;
}

.own {
    justify-content: normal;
}
.service_item_icon {
    font-weight: bold;
    padding: 4px 10px;
    background-color: #90bd7d;
    border: 1px solid #498730;
    border-radius: 50%;
}

.delete_icon {
    margin-right: 30px;
}

@media only screen and (max-width: 855px) {
    .available_services_wrap {
        width: 50%;
    }
    .services_wrap {
        font-size: 13px;
    }
}

@media only screen and (max-width: 525px) {
    .Service_profile_change_services {
        margin-top: 20px;
        display: inline-block;
        width: 100%;
    }
    .available_services_wrap {
        width: 100%;
        margin-top: 20px;
    }
    .profile_services {
        margin-left: 0;
    }
    .services_wrap {
        max-height: 250px;
        font-size: 14px;
    }
    .service_item {
        width: 100%;
    }
}